@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Ubuntu, sans-serif;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    color: #1E1D1D;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-style: normal;
}

button:hover {
    cursor: pointer;
    filter: brightness(85%);
    transition: filter 200ms ease-in;
}

input,
button,
textarea,
select,
option {
    font: 400 18px Roboto, sans-serif;
    font-style: normal;
}

#btn-personal {
        color:#ffffff;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius:4px;
        font-size:16px;
        font-weight:bold;
        text-decoration:none;
        padding:5px;
        cursor: pointer;
        padding-left:20px;
        padding-right:20px;
        margin-right: 15px;
}

#btn-personal-reverse {
        color:#EA5C1E;
        margin-right: 15px;
        border: 1px solid #EA5C1E;
        background: #fff;
        border-radius:4px;
        font-size:16px;
        font-weight:bold;
        cursor: pointer;
        text-decoration:none;
        padding:5px;
        padding-left:20px;
        padding-right:20px;
}