@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Ubuntu, sans-serif;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    color: #1E1D1D;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-style: normal;
}

button:hover {
    cursor: pointer;
    filter: brightness(85%);
    transition: filter 200ms ease-in;
}

input,
button,
textarea,
select,
option {
    font: 400 18px Roboto, sans-serif;
    font-style: normal;
}

#btn-personal {
        color:#ffffff;
        background: linear-gradient(90deg, #EA5C1E 0%, #C13818 100%);
        border-radius:4px;
        font-size:16px;
        font-weight:bold;
        text-decoration:none;
        padding:5px;
        cursor: pointer;
        padding-left:20px;
        padding-right:20px;
        margin-right: 15px;
}

#btn-personal-reverse {
        color:#EA5C1E;
        margin-right: 15px;
        border: 1px solid #EA5C1E;
        background: #fff;
        border-radius:4px;
        font-size:16px;
        font-weight:bold;
        cursor: pointer;
        text-decoration:none;
        padding:5px;
        padding-left:20px;
        padding-right:20px;
}
label{
    margin-left:15px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 300  ;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #1E1D1D;

}

.react-autosuggest__container {
    position: relative;
    width: 100%;
    height: 30px;
  }
  
  .icon {
    position: absolute;
    right:10px;  
    top:15px;
    width: 10px;
    height: 6px;
  }
  .react-autosuggest__input {
    padding: 10px 15px;
    width: 100%;
    border: none;
    border-right:1px solid #DADADA;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #1E1D1D;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
